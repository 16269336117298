
import './Profile.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import GitHubIcon from '@mui/icons-material/GitHub';
import CalculateIcon from '@mui/icons-material/Calculate';
import RadioIcon from '@mui/icons-material/Radio';
import Stack from '@mui/material/Stack';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatIcon from '@mui/icons-material/Chat';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(3),
  textAlign: 'left'
}));

const Footer = () => (
    
      <Paper sx={{position: 'fixed',bottom: 0,width: "100%"}} component="footer" square variant="outlined">
        <Grid container direction="row"  spacing={1}>          
          <Grid item xs={3}>
          <Typography variant="h6" align="center" color="#144598">
          <Link href="https://www.linkedin.com/in/polimetla" target="_blank" rel="noopener" underline="hover"><LinkedInIcon/></Link>
          </Typography>
          </Grid>

         <Grid item xs={3}>
         <Stack direction="row" gap={0}>
         <Typography variant="subtitle2" align="center" color="#144598">Blog&nbsp;</Typography>
         <Link href="https://polimetlase.wordpress.com/" target="_blank" rel="noopener" underline="hover">
         <WebAssetIcon color="success"/></Link>
         </Stack>
         </Grid>

         <Grid item xs={3}>
         <Stack direction="row" gap={0}>
         <Typography variant="subtitle2" align="center" color="#144598">Git&nbsp;</Typography>
         <Link href="https://github.com/bpolimetla" target="_blank" rel="noopener" underline="hover"><GitHubIcon sx={{ color: "#000000" }}/></Link>
        </Stack>
        
         </Grid>
         <Grid item xs={3} >
         <Stack direction="row" gap={0}>
         <Typography variant="subtitle2" align="center" color="#144598">Demo&nbsp;&nbsp;</Typography>
         <Link href="http://math.polimetla.com/" target="_blank" rel="noopener" underline="hover"><CalculateIcon color="secondary" /></Link>  &nbsp;
        {/* 
         <Link href="http://openai.polimetla.com/" target="_blank" rel="noopener" underline="hover"><ChatIcon color="secondary" /></Link>  &nbsp;
        */}
         <Link href="http://ourradio.in/" target="_blank" rel="noopener" underline="hover"><RadioIcon color="secondary" /></Link> 
         </Stack>
         </Grid>
         </Grid>

         
      </Paper>
    );


function Profile() {
  return (
    <div className="App">
      <AppBar position="fixed">
          <Toolbar>
            
          <Grid container spacing={1}>
          <Grid item xs={6}>
          <Typography variant="h6"  align="left" >Bhavani P Polimetla</Typography>
          <Typography variant="subtitle1" align="left" >Software Architect</Typography>
          </Grid>

          <Grid item xs={6} container justify="flex-end">
          <Grid style={{ display: "flex"}} xs={12}>
          <EmailIcon/><Typography >polimetla@yahoo.com</Typography></Grid>
          <Grid style={{ display: "flex" }} xs={12}>
          <LocalPhoneIcon/><Typography  >+1 201 213 8800</Typography></Grid>
          </Grid>

          </Grid>
          </Toolbar>
        </AppBar>
        <br/><br/><br/>
        <Grid container spacing={1}>
          <Grid item xs={12}>
          <Item>
          <Typography variant="h6" align="left" >+ Experienced hands-on Software Architect with 23+ years of experience and a Master’s and Bachelor’s degree in computer science.
          <br/><br/>+ Expertise in developing complex, real-time, highly available, scalable, and performance-demanding n-tier enterprise applications using AWS and Kubernetes.
          <br/><br/>+ A hands-on expert in defining Software Architecture, Process, Risk Management, Build Management, Release Management, Configuration Management, Quality Assurance, Data Modelling, and building SaaS applications from the ground up.
          <br/><br/>+ Extensive experience with Java stack, Reactive programming, Data modeling, Kubernetes, and Amazon Web Services
          <br/><br/>+ An experienced speaker, motivator, and a mentor, with outstanding communication skills that make him an ideal member of any team.
          </Typography>
          </Item>
          </Grid>
        
          <Grid item xs={12}>
          <Item>
          <Typography variant="h4" align="left" color="#144598">Technologies worked/aware</Typography>
          <Typography variant="h6" align="left" >
          Languages: Java, JavaScript, Python
          <br/>Spring: Core, Rest, Boot, JPA, R2DBC MVC, WebFlux, Batch
          <br/>Servers: Tomcat, Jetty, WebLogic, WebSphere, JBoss
          <br/>Others: GraphQL, Velocity, Free Marker, Camel, ESB, Drools, DataStax
          <br/>Developer Tools: Eclipse, IntelliJ, STS, PMD, Check Style, Jalopy, SonarQube
          <br/>RDBMS: Oracle, MySQL, PostgreSQL
          <br/>Other storage: Redis, Hazlecast, MongoDB, Graphite, Elastic Search, Cassandra
          <br/>Web UI: ReactJS / Material UI, HTML, JavaScript, CSS
          <br/>Messaging: Kafka, Apache Spark, Map Reduce, Apache Hadoop
          <br/>Source Repos: GitHub, Bitbucket
          <br/>Build Management: Gradle, Maven, Jenkins, Hudson
          <br/>Cloud: AWS, Digital Ocean, Docker, Kubernetes
          <br/>Big Data Platforms: CDAP, Hortnworks, Cloudera
          <br/>Architecture: UML, Design Patterns, Domain Driven Design, Data modeling 
          <br/>Project Management: SAFe Framework, Agile, Rally, JIRA, Atlassian Confluence
          </Typography>
          </Item>
          </Grid>

          <Grid item xs={12}>
          <Item>
          <Typography variant="h4" align="left" color="#144598"> Technical Reviewer for 4 books</Typography>
          <Typography variant="h6" align="left" >
          Jasper Reports 3.6 Development Cookbook 
          <Link href="https://www.amazon.com/JasperReports-Development-Cookbook-Bilal-Siddiqui/dp/1849510768" target="_blank" rel="noopener" underline="hover"> ISBN-13: 978-1849510769</Link>
          <br/>Spring Web Services Cookbook 
          <Link href="https://www.amazon.com/Spring-Web-Services-2-Cookbook/dp/1849515824" target="_blank" rel="noopener" underline="hover"> ISBN: 978-1849515825</Link>
          <br/>Maven for Eclipse 
          <Link href="https://www.amazon.com/Maven-Eclipse-Sanjay-Shah/dp/178398712X" target="_blank" rel="noopener" underline="hover"> ISBN: 978-1783987122</Link>
          <br/>Mastering Apache Maven 3 
          <Link href="https://www.amazon.com/Mastering-Apache-Maven-Prabath-Siriwardena/dp/1783983868" target="_blank" rel="noopener" underline="hover"> ISBN-13: 978-1783983865</Link>
          </Typography>
          </Item>
          </Grid>

          <Grid item xs={12}>
          <Item>
          <Typography variant="h4" align="left" color="#144598">Open source contributor</Typography>
          <Typography variant="h6" align="left" >
          Data Dictionary Generator 
          <Link href="http://ddgen.wordpress.com/" target="_blank" rel="noopener" underline="hover"> http://ddgen.wordpress.com/</Link>
          </Typography>
          </Item>
          </Grid>

          <Grid item xs={12}>
          <Item>
          <Typography variant="h4" align="left" color="#144598">Education</Typography>
          <Typography variant="h6" align="left" >
          Master of Computer Applications (MCA), Andhra University
          <br/>Bachelor of Science (B.Sc), Computer Science, Mathematics, Physics, Andhra University
          <br/>MongoDB for Java Developers – 2017
          <br/>Hadoop Platform and Application Framework, University of California, San Diego – 2017

          </Typography>
          </Item>
          </Grid>

          <Grid item xs={12}>
          <Item>
          <Typography variant="h4" align="left" color="#144598">Certifications</Typography>
          <Typography variant="h6" align="left" >
          TOGAF Certified Architect, 2022
          <br/>AWS Certified Solutions Architect – Associate , 2022
          <br/>Shaping up with AngularJS, Code School, 2016
          <br/>Scaled Agile Framework Agilist Certification Program, 2015
          <br/>ITIL Foundation Exam, 2015
          <br/>Sun Certified Enterprise Architect (SCEA), 2009
          <br/>Brainbench Certified in 20+ subjects
          <br/>Microsoft Certified Professional VC++, 1998
          <br/><br/>
          </Typography>
          </Item>
          </Grid>
{/* 
          <Grid item xs={12}>
          <Item>
          <Typography variant="h4" align="left" color="#144598">Achievements/Awards</Typography>
          <Typography variant="h6" align="left" >
          Bravo BFLSlite Award, 1998 @BFL Software 
          <br/>Bravo BFLSlite Award, 1998 @BFL Software
          <br/>Special Recognition as part of SEI CMM Level 4 process, 1999 @BFL Software
          <br/>Bravo BFLSlite Award, 2000 @BFL Software
          <br/>Appreciation Award from ABB, 2004 @Mindtree
          <br/>Received a total of 24 recommendations in LinkedIn, 2005-14
          <br/>Above and Beyond Award, 20015 @Broadcom
          <br/>Above and Beyond Award, 20016 @Broadcom
          <br/>Guardian of the Galaxy Team Award, 2018 @CognitiveScale
          <br/><br/>
          </Typography>
          </Item>
          </Grid>
*/}
          </Grid>

<Footer/>
    </div>
  );
}

export default Profile;
