import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useState,useEffect } from 'react';
import Profile from './Profile';

export default function Card2() {

  
  const [seconds, setSeconds] = useState(0);
  const [count, setCount] = useState(0);
  const [profile, setProfile] = useState(false);

  const imageList = ["/images/bird_nest.jpg",
                    "/images/bee_comb.jpg",
                     "/images/termite.jpg","/images/vespa.jpg","/images/ant_nest.jpg",
                     "/images/ant.jpg" ,"/images/spider.jpeg"];

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
      
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCount(seconds % 7);
  }, [seconds]);

  function onClick(){
    console.log('The link was clicked.');
    //setProfile(true);   //Enable this to move forward
  }

  return (
<>
    {!profile ? 
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing="2">
          
            <Grid item>
            <br/><br/><br/><br/>
              <Paper
                sx={{
                  height: 100,
                  width: 300,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              > 
      
              <Card sx={{ maxWidth: 300 }}>
                
                <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Bhavani Polimetla
                </Typography>
                <a href="#" onClick= {onClick}>
                <Typography variant="subtitle2" sx={{ color: "#0096c7" }}>
                Software Architect, Learning from Nature
                </Typography>
                </a>
                </CardContent>

                <a href="#" onClick= {onClick}>
                <CardMedia
                sx={{ height: 300 }}
                image= {imageList[count]}
                title="Learning from Nature"
                />
                </a>
              </Card>

              </Paper>
            </Grid>
          
        </Grid>
      </Grid>
      </Grid>
      : 
      <><Profile/></>
    }

</>

  );
}